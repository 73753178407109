export const AVAST_SUPPORT_PHONE_MAP: StringMap = {
  GB: '448000321430',
  DE: '498001013815',
  FR: '33805542423',
  AU: '611800417836',
  NZ: '64800461302',
  BR: '558007620196',
  MX: '528002690181',
  IT: '39800147648',
  ES: '34900031433',
  NL: '318002929204',
  PL: '48800013019',
  CH: '41800000521',
  BE: '3280089479',
  SE: '4620980704',
  IE: '3531800900670',
};

export const AVG_SUPPORT_PHONE_MAP: StringMap = {
  GB: '448000324486',
  DE: '498000663311',
  FR: '33805542429',
  AU: '611800417842',
  NZ: '64800461296',
  BR: '558007620197',
  MX: '528004227733',
  IT: '39800147650',
  ES: '34900031019',
  NL: '318002354353',
  PL: '48800013039',
  CH: '41800000288',
  BE: '3280089481',
  SE: '46201408120',
  IE: '3531800900672',
};
export const PARTNER_SUPPORT_URL_MAP: StringMap = {
  avast_intl: 'https://support.avast.com/en-us/article/avast-secure-identity-faq/',
  avg_intl:
    'https://support.avg.com/SupportArticleView?l=en&urlName=avg-secure-identity-faq&q=secure+identity&supportType=home',
};
