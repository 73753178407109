import store from './configureStore';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

const deDe = () => {
  return import('./lang/de-DE.json');
};
/*const enCa = () => {
  return import('./lang/en-CA.json');
};
*/
const enUs = () => {
  return import('./lang/en-US.json');
};

const enGb = () => {
  return import('./lang/en-GB.json');
};
/*const frFr = () => {
  return import('./lang/fr-FR.json');
};
*/
const enIN = () => {
  return import('./lang/en-IN.json');
};

const ja = () => {
  return import('./lang/ja.json');
};
const frCA = () => {
  return import('./lang/fr-CA');
};
const frFR = () => {
  return import('./lang/fr-FR');
};
const enAu = () => {
  return import('./lang/en-AU.json');
};
const enNz = () => {
  return import('./lang/en-NZ.json');
};
const daDk = () => {
  return import('./lang/da-DK.json');
};
const esEs = () => {
  return import('./lang/es-ES.json');
};
const fiFi = () => {
  return import('./lang/fi-FI.json');
};
const itIt = () => {
  return import('./lang/it-IT.json');
};
const nbNo = () => {
  return import('./lang/nb-NO.json');
};
const nlNl = () => {
  return import('./lang/nl-NL.json');
};
const plPl = () => {
  return import('./lang/pl-PL.json');
};
const svSe = () => {
  return import('./lang/sv-SE.json');
};
const zhTW = () => {
  return import('./lang/zh-TW.json');
};
const ptBR = () => {
  return import('./lang/pt-BR.json');
};
const trTr = () => {
  return import('./lang/tr-TR.json');
};
const thTh = () => {
  return import('./lang/th-TH.json');
};
const koKr = () => {
  return import('./lang/ko-KR.json');
};
const filPh = () => {
  return import('./lang/fil-PH.json');
};

export let languages = {
  da: daDk,
  de: deDe,
  'de-AT': deDe,
  'de-CH': deDe,
  'de-DE': deDe,
  en: enUs,
  //  'en-CA': enCa,
  'en-AU': enAu,
  'en-CA': enUs, //explicitly mapping en-CA to enUs
  'en-US': enUs,
  'en-GB': enGb,
  'en-NZ': enNz,
  'en-IE': enGb,
  'en-IN': enIN,
  'en-BZ': enUs,
  'en-KY': enUs,
  'en-TT': enUs,
  es: esEs,
  'es-ES': esEs,
  'es-CL': esEs,
  'es-CO': esEs,
  'es-AR': esEs,
  'es-PE': esEs,
  'es-AW': esEs,
  'es-BO': esEs,
  'es-KY': esEs,
  'es-CR': esEs,
  'es-DO': esEs,
  'es-EC': esEs,
  'es-SV': esEs,
  'es-GT': esEs,
  'es-HN': esEs,
  'es-CW': esEs,
  'es-SX': esEs,
  'es-NI': esEs,
  'es-PA': esEs,
  'es-PY': esEs,
  'es-TT': esEs,
  'es-UY': esEs,
  'es-VE': esEs,
  'es-MX': esEs,
  //  fr: frFr,
  //  'fr-FR': frFr,
  'fr-BE': frCA,
  'fr-CH': frCA,
  fi: fiFi,
  fil: filPh,
  'fil-PH': filPh,
  it: itIt,
  'it-CH': itIt,
  'it-IT': itIt,
  ja: ja,
  'ja-JP': ja,
  ko: koKr,
  'ko-KR': koKr,
  nb: nbNo,
  nn: nbNo,
  no: nbNo,
  nl: nlNl,
  'nl-BE': nlNl,
  'nl-AW': nlNl,
  'nl-CW': nlNl,
  'nl-NL': nlNl,
  'nl-SX': nlNl,
  pl: plPl,
  'pl-PL': plPl,
  pt: ptBR,
  'pt-BR': ptBR,
  sv: svSe,
  'sv-FI': svSe,
  'sv-SV': svSe,
  'sv-SE': svSe,
  th: thTh,
  'th-TH': thTh,
  tl: filPh, // use filipino for tagalog lang codes
  'tl-PH': filPh, // use filipino for tagalog lang codes
  tr: trTr,
  'tr-TR': trTr,
  zh: zhTW,
  'zh-CN': zhTW,
  'zh-TW': zhTW,
  'zh-HK': zhTW,
};
//checking if env is not production, need to remove the check once all french translations are tested
if (window.REACT_APP_ENABLE_FRENCH_TRANSLATION === true) {
  languages = Object.assign(languages, {fr: frCA, 'fr-CA': frCA, 'fr-FR': frFR});
}
let langCache = undefined; // caches current language until page refresh
let langCodeCache = undefined; // caches lang code until page refresh
let abTestLoaded = false; // check A/B test loaded

// angular library browser language detection
export function getBrowserLanguage() {
  const nav = window.navigator;
  const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];
  let language = null;

  // support for HTML 5.1 "navigator.languages"
  if (Array.isArray(nav.languages)) {
    for (let i = 0; i < nav.languages.length; i++) {
      language = nav.languages[i];
      if (language && language.length) {
        return language;
      }
    }
  }

  // support for other well known properties in browsers
  for (let i = 0; i < browserLanguagePropertyKeys.length; i++) {
    language = nav[browserLanguagePropertyKeys[i]];
    if (language && language.length) {
      return language;
    }
  }
  return null;
}

/**
 * Replaces ${key0} ${key1} ${key2} ... strings in templateStr with corresponding args.
 * @param {string} templateStr - template string such as "${key0} some text ${key1}"
 * @param {object} replacements - object literal of key value pairs so ${key} in templateStr is replaced with value.  Value must be type string or number
 * @return {string} - interpolated string with placeholders replaced by corresponding values in replacements
 */
export function interpolate(templateStr, replacements) {
  if (!templateStr) {
    return templateStr;
  }
  let result = templateStr;
  Object.keys(replacements).forEach((key) => {
    const val = replacements[key];
    if (typeof val === 'string' || typeof val === 'number') {
      const target = '${' + key + '}';

      // building a RegExp for target string requires that we escape any characters that have special meaning to regex
      const escapedTarget = target.replace(/([${}])/g, '\\$1');
      result = result.replace(new RegExp(escapedTarget, 'g'), val);
    }
  });
  return result;
}

/**
 * Function connects to redux store and uses browser language code provided from member api
 * which pulls from accept-language header on incoming http requests
 * Function defaults to previous method of getting browser language as a default
 * @return {string} - language code
 */
export function getLangCode() {
  const reduxStore = store.getState();
  return _get(reduxStore, 'auth.user.browserLangCode', getBrowserLanguage());
}
export function getPlan() {
  const reduxStore = store.getState();
  return _get(reduxStore, 'auth.user.primaryMember.plan.name', '');
}
export function getCountryCode() {
  const reduxStore = store.getState();
  return _get(reduxStore, 'auth.user.countryCode', 'US');
}
export function stringBundle(langCode, callback) {
  if (languages.hasOwnProperty(langCode)) {
    return getLangBundle(langCode, callback);
  }
  // if full lang code doesn't match, try matching without the region code
  const langCodeSplit = langCode.split('-');
  if (langCodeSplit.length === 2) {
    const langCodeOnly = langCodeSplit[0];
    if (languages.hasOwnProperty(langCodeOnly)) {
      return getLangBundle(langCodeOnly, callback);
    }
  }
  // unrecognized langCode, default to en-US
  return stringBundle('en-US');
}

function getLangBundle(langCode, callback) {
  langCache = langCache || {};
  if (langCode !== langCodeCache) {
    languages[langCode]().then((data) => {
      Object.assign(langCache, data);
      callback && callback();
    });
  }
  //Get A/B test template strings from adobe target and overwrite the default strings
  if (!abTestLoaded && window.abTestCopyData) {
    abTestLoaded = true;
    const variantCopyData = window.abTestCopyData[langCode];
    if (!_isEmpty(variantCopyData)) {
      Object.keys(variantCopyData).forEach((key) => {
        if (typeof variantCopyData[key] === 'string') {
          langCache[key] = variantCopyData[key].replace(/{/g, '${');
        }
      });
    }
  }
  langCodeCache = langCode;
  return langCache;
}
