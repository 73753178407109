import * as partnerConstants from '../constants/partnerConstants';

// Helper methods for getting partner CSS URLs and checking if a partner-supplied URL is valid.

/**
 * Validates partner URL.  This SPA should only dynamically load valid partner URLs.
 * This method should be used when the partner sends a message to the SPA to dynamically load CSS files.
 * @param {string} url - partner URL, e.g. 'https://examplepartner.com/style.css'
 * @param {string} expectedExtension - allowed extension of the URL, e.g. '.css'
 * @return {boolean} true if the URL is a valid partner URL, false otherwise.
 */
export function isValidPartnerUrl(url: string, expectedExtension: string) {
  if (typeof url !== 'string') {
    return false;
  }
  if (!url.endsWith(expectedExtension)) {
    return false;
  }
  if (url.length > 255) {
    return false;
  }

  // Valid prefixes should be defined in config.js.
  // Prefix must include the trailing '/' to filter out malicious origins that start with what looks like a valid partner path, except for those with localhost IP
  const validPrefixes = [
    window.REACT_APP_PARTNER_CSS_HOST + window.REACT_APP_PARTNER_CSS_PATH + '/',
    'https://cdn.lifelock.com/',
  ]; // add more valid prefixes here if necessary, such as partner CDNs
  if (window.REACT_APP_LOCAL_HTTP_HOST) {
    validPrefixes.push(window.REACT_APP_LOCAL_HTTP_HOST);
  }
  if (window.REACT_APP_LOCAL_HTTPS_HOST) {
    validPrefixes.push(window.REACT_APP_LOCAL_HTTPS_HOST);
  }

  const hasValidPrefix =
    validPrefixes.filter((prefix) => {
      return url.startsWith(prefix);
    }).length > 0;
  if (!hasValidPrefix) {
    return false;
  }
  return true;
}

/**
 * Returns an array of CSS URLs specific to whiteLabelClient.
 * This method should be used when automatically loading a theme as part of the partner code NSL login flow.
 * @property {string} whiteLabelClient - business partner identifier, used by clients to determine which theme to activate
 * @returns {string[]} CSS URLs
 */
export function getCssUrls(whiteLabelClient: string): string[] {
  switch (whiteLabelClient) {
    case 'ee':
      return [window.REACT_APP_PARTNER_CSS_HOST + window.REACT_APP_PARTNER_CSS_PATH + '/partner-ee.css'];
    case 'avast':
      return [window.REACT_APP_PARTNER_CSS_HOST + window.REACT_APP_PARTNER_CSS_PATH + '/partner-avast.css'];
    default:
      return [];
  }
}

/**
 * Loads the partner theme corresponding to partner id, ONLY when partner user logs in through NSL with partner code.
 * This involves loading CSS that override styles, and overriding window variables, that are referenced by the SPA.
 * @param {string} whiteLabelClient - Identifies the white label client associated with the current user. Used to set data-theme attribute of 'html' element.
 */
export function loadPartnerTheme(whiteLabelClient: string) {
  switch (whiteLabelClient) {
    case 'ee':
      loadCssUrls(getCssUrls(whiteLabelClient));
      overrideConfigs({
        collapseLeftNav: false,
        useFeatureLayout: false,
        supportHours: '',
        isNestedIframe: false,
        supportPhone: '0800 956 6000',
        supportUrl: 'https://ee.co.uk/contact-ee/lost-stolen/',
        productName: 'ID Theft Protection',
      });
      break;
    case 'avast':
      loadCssUrls(getCssUrls(whiteLabelClient));
      overrideConfigs({
        collapseLeftNav: false,
        useFeatureLayout: false,
        isNestedIframe: false,
        supportHours: '',
        supportPhone: '1-855-741-4323',
        supportUrl: 'https://support.avast.com/en-us/',
        productName: 'ID Theft Protection',
      });
      break;
    /* Example of partner hosting files.  Remember to add partner host and path with trailing slash to isValidPartnerUrl method
      case 'verizon':
        // Some partners may self host their CSS, so we should use the hosts they specify and confirm
        loadCssUrls(['https://verizon.com/partner-verizon.css']);
        overrideConfigs({supportPhone: '1.800.VERIZON', supportUrl: 'https://www.verizon.com/support/'});
        break;
      */
    default:
      // Handle the scenario when whiteLabelClient does not match any of the specified cases
      break;
  }
  const htmlEl = document.querySelector('html');
  if (htmlEl) {
    htmlEl.setAttribute('data-theme', whiteLabelClient); // set the data-theme attribute to whiteLabelClient value
  }
}

/**
 * Returns a supportPhone from the constant map based on the user country code.
 * @param {string} countryCode - logged-in user countryCode
 * @param {string} whiteLabelClient - whiteLabelClient string val
 * @returns {string} supportPhone
 */
export function getSupportPhone(countryCode: string, whiteLabelClient: string): string {
  if (whiteLabelClient === 'avast_intl') {
    return partnerConstants.AVAST_SUPPORT_PHONE_MAP[countryCode] || '';
  } else if (whiteLabelClient === 'avg_intl') {
    return partnerConstants.AVG_SUPPORT_PHONE_MAP[countryCode] || '';
  }
  return '';
}

/**
 * Returns a boolean if a whiteLabelClient custom configuration map is maintained by dsp app.
 * @param {string} whiteLabelClient - business partner identifier, used by clients to determine which theme to activate
 * @returns {boolean}
 */
export function isWhiteLabelClientCustomConfig(whiteLabelClient: string): boolean {
  return whiteLabelClient === 'avast_intl' || whiteLabelClient === 'avg_intl';
}

/**
 * Returns an object containing configuration specific to whiteLabelClient.
 * @param {string} whiteLabelClient - business partner identifier, used by clients to determine which theme to activate
 * @param {string} countryCode - logged-in user countryCode
 * @returns {Object} partnerConfig
 */
export function getCustomPartnerConfig(whiteLabelClient: string, countryCode: string): PartnerConfig {
  // using the react_app configs since, OVERRIDE_CONFIGS event will be completed by the time userCountryCode is available
  // except for supportHours, supportPhone, supportUrl, rest of the mandatory configs are passed by OVERRIDE_CONFIGS maintained by whiteLabelClient
  // using empty string value for supportHours as the requirement is to show the default support hours values set in the config for Norton
  const partnerConfig: PartnerConfig = {
    collapseLeftNav: window.REACT_APP_COLLAPSE_LEFT_NAV,
    isNestedIframe: window.REACT_APP_IS_NESTED_IFRAME,
    productName: window.REACT_APP_PRODUCT_NAME,
    supportHours: '',
    supportPhone: '',
    supportUrl: '',
    useFeatureLayout: window.REACT_APP_USE_FEATURE_LAYOUT,
  };
  const supportUrl = partnerConstants.PARTNER_SUPPORT_URL_MAP[whiteLabelClient] || '';
  const supportPhone = getSupportPhone(countryCode, whiteLabelClient);

  return Object.assign(partnerConfig, {
    supportPhone,
    supportUrl,
  });
}

/**
 * Overrides specific config.js values using values from partnerConfig.
 * @param {PartnerConfig} partnerConfig - Object containing string values to override support URLs and phone numbers specified in config.js
 */
export function overrideConfigs(partnerConfig: PartnerConfig) {
  const {
    collapseLeftNav,
    useFeatureLayout,
    isNestedIframe,
    supportPhone,
    supportUrl,
    supportHours,
    productName,
    legalUrl,
    legalUrlText,
    smmUrl,
    smmUrlText,
  } = partnerConfig;

  if (collapseLeftNav) {
    window.REACT_APP_COLLAPSE_LEFT_NAV = collapseLeftNav;
  }
  if (useFeatureLayout) {
    window.REACT_APP_USE_FEATURE_LAYOUT = useFeatureLayout;
  }
  if (isNestedIframe) {
    window.REACT_APP_IS_NESTED_IFRAME = isNestedIframe;
  }
  if (supportPhone) {
    window.REACT_APP_PHONE_LOCK_SUPPORT_PHONE = supportPhone;
    window.REACT_APP_ID_ADVISOR_JAPAN_SUPPORT_PHONE = supportPhone;
    window.REACT_APP_RESTORATION_SUPPORT_PHONE_NZ = supportPhone;
    window.REACT_APP_RESTORATION_SUPPORT_PHONE_FR = supportPhone;
    window.REACT_APP_RESTORATION_SUPPORT_PHONE_US = supportPhone;
    window.REACT_APP_RESTORATION_SUPPORT_PHONE_IN = supportPhone;
    window.REACT_APP_RESTORATION_SUPPORT_PHONE_DE = supportPhone;
    window.REACT_APP_RESTORATION_SUPPORT_PHONE_GB = supportPhone;
    window.REACT_APP_RESTORATION_SUPPORT_PHONE_BR = supportPhone;
    window.REACT_APP_RESTORATION_SUPPORT_PHONE_MX = supportPhone;
    window.REACT_APP_RESTORATION_SUPPORT_PHONE_JP = supportPhone;
    window.REACT_APP_RESTORATION_SUPPORT_PHONE_AU = supportPhone;
  }
  if (supportUrl) {
    window.REACT_APP_ID_ADVISOR_SUPPORT_URL = supportUrl;
    window.REACT_APP_ID_ADVISOR_HELP = supportUrl;
    window.REACT_APP_ALERT_DETAIL_FOOTER_SUPPORT_INFO = supportUrl;
    window.REACT_APP_CREDIT_FREEZE_IRS_LEARN_MORE = supportUrl;
    window.REACT_APP_FREEZE_BANK_LEARN_MORE_ARTICLE = supportUrl;
    window.REACT_APP_FREEZE_CHILD_CREDIT_LEARN_MORE_ARTICLE = supportUrl;
    window.REACT_APP_FREEZE_CREDIT_LEARN_MORE_ARTICLE = supportUrl;
    window.REACT_APP_FREEZE_EQUIFAX_LEARN_MORE = supportUrl;
    window.REACT_APP_FREEZE_EVERIFY_LEARN_MORE = supportUrl;
    window.REACT_APP_FREEZE_IDENITY_LOCK_LEARN_MORE_ARTICLE = supportUrl;
    window.REACT_APP_FREEZE_PAYDAY_LEARN_MORE_ARTICLE = supportUrl;
    window.REACT_APP_FREEZE_UTILITIES_LEARN_MORE_ARTICLE = supportUrl;
    window.REACT_APP_FREEZE_AND_LOCK_DIFFERENT_LINK = supportUrl;
  }
  if (supportHours) {
    window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_NZ = supportHours;
    window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_FR = supportHours;
    window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_US = supportHours;
    window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_IN = supportHours;
    window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_DE = supportHours;
    window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_GB = supportHours;
    window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_BR = supportHours;
    window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_MX = supportHours;
    window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_JP = supportHours;
    window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_AU = supportHours;
  }
  if (productName) {
    window.REACT_APP_PRODUCT_NAME = productName;
  }
  if (legalUrl) {
    window.REACT_APP_LEGAL_URL = legalUrl;
  }
  if (legalUrlText) {
    window.REACT_APP_LEGAL_LINK_TEXT = legalUrlText;
  }
  if (smmUrl) {
    window.REACT_APP_SMM_LINK = smmUrl;
  }
  if (smmUrlText) {
    window.REACT_APP_SMM_LINK_TEXT = smmUrlText;
  }
}

/**
 * Loads CSS URLs into the SPA page.
 * Typically used by partner pages to override styles in the SPA.
 * @param {string[]} cssUrls - CSS URLs, typically provided by a partner page that iframes this SPA
 */
export function loadCssUrls(cssUrls: string[]) {
  if (!Array.isArray(cssUrls)) {
    return;
  }
  // remove old link elements associated with partner CSS files
  document.querySelectorAll('[data-testid=partnerCss]').forEach((el) => {
    el.remove();
  });
  // add link element for each partner CSS file
  cssUrls.forEach((url) => {
    if (!isValidPartnerUrl(url, '.css')) {
      return;
    }

    const partnerCssLink = document.createElement('link');
    partnerCssLink.href = url;
    partnerCssLink.rel = 'stylesheet';
    partnerCssLink.setAttribute('data-testid', 'partnerCss');
    // Cannot append to head because webpack will dynamically append CSS chunks to the head multiple times.
    // The CSS rules in those chunks could override the partner styles, which we do not want.
    document.body.appendChild(partnerCssLink); // last CSS rules win
  });
}

// We currently do not allow partners to load JS URLs.
// There is no use case requiring that feature. Overriding specific config values can be done in App.tsx.
// Also not loading partner JS removes a potential source of bugs and security issues.
