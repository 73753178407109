import _get from 'lodash/get';
import {callEfx, getCreditFulfillment, getCreditPullInfo} from '../actions/credit';
import {getLangCode, interpolate} from '../stringBundle';

const EFX_PARTNER_CODE = 'EFX';
const TU_PARTNER_CODE = 'TU';
const EXP_PARTNER_CODE = 'EXP';

/**
 * Returns an array of credit feature types based on the provided parameters.
 *
 * @param {boolean} isPull1BReportAllowed - Flag indicating if pulling a 1B report is allowed.
 * @param {string} topTabId - The ID of the top tab.
 * @returns {Array<string>} An array of credit feature types.
 */
const getCreditFeatures = (isPull1BReportAllowed: boolean, topTabId: string): Array<string> => {
  if (topTabId === TU_PARTNER_CODE || topTabId === EXP_PARTNER_CODE) {
    return ['CRDTRPT_ADHOC_3B', 'CRDTSCR_ADHOC_3B'];
  }
  return isPull1BReportAllowed ? ['CRDTRPT_ADHOC_1B', 'CRDTSCR_ADHOC_1B'] : ['CRDTSCR_ADHOC_1B'];
};

/**
 * Handles the success scenario for credit fulfillment.
 * Dispatches actions to get credit pull information and call EFX.
 *
 * @param {Function} dispatch - Redux dispatch function.
 * @param {string} accountId - Account ID of the user.
 * @param {StringMap} localizedStringBundle - Localized string bundle for the current language.
 * @param {boolean} is3BUser - Flag indicating if the user is a 3B user.
 * @param {JWT} jwt - JSON Web Token for authentication.
 */
const handleCreditFulfillmentSuccess = async(
  dispatch: Function,
  accountId: string,
  localizedStringBundle: StringMap,
  is3BUser: boolean,
  jwt: JWT
) => {
  try {
    const pullInfoRes = await dispatch(getCreditPullInfo(accountId));
    const creditPullInfo = _get(pullInfoRes, 'pullInfo', {});
    await dispatch(callEfx(accountId, localizedStringBundle, getLangCode(), is3BUser, creditPullInfo, jwt));
  } catch (pullInfoError) {
    dispatch({type: 'CREDIT_SCORE_STATUS', credit_score_status: 'error'});
    dispatch({type: 'CREDIT_REPORT_STATUS', credit_report_status: 'error'});
  }
};

/**
 * Processes the Equifax credit pull by dispatching various actions.
 * Handles the success and failure scenarios for credit fulfillment.
 *
 * @param {Object} creditPullInfo - Information about the credit pull.
 * @param {string} topTabId - The ID of the top tab.
 * @param {boolean} is3BUser - Flag indicating if the user is a 3B user.
 * @param {Object} props - Properties containing dispatch function and authentication information.
 * @param {Function} props.dispatch - Redux dispatch function.
 * @param {Object} props.auth - Authentication information.
 * @param {Object} props.auth.user - User information.
 * @param {Object} props.auth.user.primaryMember - Primary member information.
 * @param {string} props.auth.user.primaryMember.accountId - Account ID of the user.
 * @param {StringMap} localizedStringBundle - Localized string bundle for the current language.
 * @param {JWT} jwt - JSON Web Token for authentication.
 */
export const processCreditEquifaxPull = async(
  creditPullInfo: $TSFixMe,
  topTabId: string,
  is3BUser: boolean,
  props: $TSFixMe,
  localizedStringBundle: StringMap,
  jwt: JWT
) => {
  const {isPull1BReportAllowed} = creditPullInfo;
  const creditFeatures = getCreditFeatures(isPull1BReportAllowed, topTabId);
  const accountId: string = _get(props.auth, 'user.primaryMember.accountId', '');

  try {
    const fulfillmentResponse = await props.dispatch(
      getCreditFulfillment('equifax', creditFeatures, topTabId, 'POST', accountId)
    );

    if (fulfillmentResponse.type === 'CREDIT_FULFILLMENT_SUCCESS') {
      props.dispatch({type: 'CREDIT_SCORE_STATUS', credit_score_status: 'loading'});
      props.dispatch({type: 'CREDIT_REPORT_STATUS', credit_report_status: 'loading'});
      await handleCreditFulfillmentSuccess(props.dispatch, accountId, localizedStringBundle, is3BUser, jwt);
    } else {
      props.dispatch({
        type: 'CREDIT_FULFILLMENT_FAILURE',
        creditFulfillmentStatus: 'error',
        error: fulfillmentResponse.error,
      });
    }
  } catch (error) {
    props.dispatch({
      type: 'CREDIT_FULFILLMENT_FAILURE',
      creditFulfillmentStatus: 'error',
      error: error,
    });
  }
};

export type LogDataObj = {
  feature: string;
  actionType?: string;
  message?: string;
  startTime: number;
  accountId?: string;
  clientId: string;
  statusCode?: number;
  error?: object | string;
};

/**
 * Returns Object of gaAction and text for Ga action based on topTabId and creditPullInfo
 * @param {Object} creditPullInfo -
 * @param {String} topTabId
 * @param {Object} localizedStringBundle
 * @returns {Object} it returns object of gaAction and text.
 */
export const getGaActionAndText = (creditPullInfo: $TSFixMe, topTabId: string, localizedStringBundle: StringMap) => {
  if (topTabId === TU_PARTNER_CODE || topTabId === EXP_PARTNER_CODE) {
    return {text: localizedStringBundle['3B_REPORT_MODAL_TEXT'], gaAction: 'New 3B Report'};
  }
  if (creditPullInfo?.isPull1BScoreAllowed) {
    return {text: localizedStringBundle['1B_SCORE_MODAL_TEXT'], gaAction: 'New 1B Score'};
  }
  return {text: localizedStringBundle['1B_REPORT_MODAL_TEXT'], gaAction: 'New 1B Report'};
};

/**
 * Returns label and action type to trigger Ga event.
 * @param {Object} creditPullInfo -
 * @param {String} topTabId
 * @param {Object} localizedStringBundle
 * @returns {Array} it returns label and action for Ga event.
 */
export const getParamsForGaEvent = (creditPullInfo: $TSFixMe, topTabId: string, localizedStringBundle: StringMap) => {
  const label = topTabId === EFX_PARTNER_CODE ? `${topTabId} Get latest score & report` : `${topTabId} Confirm report`;
  const action =
    topTabId === EFX_PARTNER_CODE
      ? 'click'
      : getGaActionAndText(creditPullInfo, topTabId, localizedStringBundle).gaAction;
  return [label, action];
};

/**
 * Returns frequencyText according to frequencyUnitType
 * @param {string} frequencyType - credit frequency type {Days, Months, Year}
 * @param {Object} localizedStringBundle - string bundle according to country code
 * @returns {string} it returns frequency string from localizedStringBundle
 */
export const getFrequencyText = (frequencyType: string, localizedStringBundle: StringMap) => {
  switch (frequencyType) {
    case 'Days':
      return localizedStringBundle.DAILY;
    case 'Months':
      return localizedStringBundle.MONTHLY;
    case 'Years':
      return localizedStringBundle.ANNUAL;
    default:
      return '';
  }
};

/**
 * Returns frequencyText according to frequencyUnitType
 * @param {string} frequencyText - credit frequency text {daily, monthly, annual}
 * @param {Object} localizedStringBundle - string bundle according to country code
 * @param {boolean} isTextForToolTip - boolean var to differentiate whether the text is for modal or tooltip
 * @returns {string} it returns feature detail from localizedStringBundle
 */
export const getFeatureDetail = (
  frequencyText: string,
  localizedStringBundle: StringMap,
  isTextForToolTip: boolean
) => {
  let featureDetailStr = '';

  if (isTextForToolTip) {
    featureDetailStr =
      frequencyText === 'annual'
        ? interpolate(localizedStringBundle.TU_CREDIT_TEXT2, {frequencyText})
        : interpolate(localizedStringBundle.TU_CREDIT_TEXT1, {frequencyText});
  } else {
    featureDetailStr =
      frequencyText === 'annual'
        ? interpolate(localizedStringBundle.TU_RETRIEVE_REPORT_TEXT2, {frequencyText})
        : interpolate(localizedStringBundle.TU_RETRIEVE_REPORT_TEXT1, {frequencyText});
  }
  return featureDetailStr;
};
